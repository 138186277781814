<template>
  <svg
    fill="none"
    height="2"
    viewBox="0 0 12 2"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1L1 1"
      stroke="white"
      stroke-linecap="square"
      stroke-width="1.5"
    />
  </svg>
</template>
